h1, .h1 {
  font-family: Playfair Display, serif;
  font-size: 3rem;
  line-height: 1.375;
}

h2, .h2 {
  font-family: Playfair Display, serif;
  font-size: 2.25rem;
  line-height: 1.375;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

h4, h5, h6 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

strong {
  font-weight: 700;
}

p {
  line-height: 2;
}

.button {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(164 116 0 / var(--tw-border-opacity));
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: table;
}

.button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(164 116 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

/*# sourceMappingURL=johnGreenish.css.map */
