/* Typography */
h1,
.h1 {
  @apply font-playfair text-5xl leading-snug;
}

h2,
.h2 {
  @apply font-playfair text-4xl leading-snug;
}

h3 {
  @apply text-2xl font-bold;
}

h4,
h5,
h6 {
  @apply text-xl font-bold;
}

strong {
  @apply font-bold;
}

p {
  @apply leading-loose;
}

.button {
  @apply table border border-gold px-4 py-2 transition hover:bg-gold hover:text-white;
}
